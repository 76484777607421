exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-record-js": () => import("./../../../src/pages/record.js" /* webpackChunkName: "component---src-pages-record-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-contents-jsx-content-file-path-src-contents-bibliography-mdx": () => import("./../../../src/templates/contents.jsx?__contentFilePath=/home/runner/work/borderscape/borderscape/src/contents/bibliography.mdx" /* webpackChunkName: "component---src-templates-contents-jsx-content-file-path-src-contents-bibliography-mdx" */),
  "component---src-templates-contents-jsx-content-file-path-src-contents-credits-mdx": () => import("./../../../src/templates/contents.jsx?__contentFilePath=/home/runner/work/borderscape/borderscape/src/contents/credits.mdx" /* webpackChunkName: "component---src-templates-contents-jsx-content-file-path-src-contents-credits-mdx" */),
  "component---src-templates-contents-jsx-content-file-path-src-contents-site-list-mdx": () => import("./../../../src/templates/contents.jsx?__contentFilePath=/home/runner/work/borderscape/borderscape/src/contents/site-list.mdx" /* webpackChunkName: "component---src-templates-contents-jsx-content-file-path-src-contents-site-list-mdx" */),
  "component---src-templates-contents-jsx-content-file-path-src-contents-webgis-mdx": () => import("./../../../src/templates/contents.jsx?__contentFilePath=/home/runner/work/borderscape/borderscape/src/contents/webgis.mdx" /* webpackChunkName: "component---src-templates-contents-jsx-content-file-path-src-contents-webgis-mdx" */)
}

